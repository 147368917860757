<template>
  <div>
    <div v-if="loader">
      <PageLoader></PageLoader>
    </div>
    <div v-else class="activities-holder">
      <div class="recent-activity-holder">
        <h6 class="recent-activity-text">Recent Activities</h6>
        <div>
          <div
            class="recent-holder"
            v-for="(item, index) in notifications"
            :key="index"
          >
            <div class="d-flex">
              <div class="rooundedCircleBlue" v-if="index % 2 === 0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M1.33301 5.66602H8.99967"
                    stroke="white"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4 11H5.33333"
                    stroke="white"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7 11H9.66667"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6663 7.35398V10.7407C14.6663 13.0807 14.073 13.6673 11.7063 13.6673H4.29301C1.92634 13.6673 1.33301 13.0807 1.33301 10.7407V5.26065C1.33301 2.92065 1.92634 2.33398 4.29301 2.33398H8.99967"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11 4.00065L12 5.00065L14.6667 2.33398"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="rooundedCirclePink" v-if="index % 2 !== 0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12.8673 5.28097V8.71431C12.8673 10.7676 11.694 11.6476 9.93396 11.6476H4.07395C3.77395 11.6476 3.48729 11.621 3.22062 11.561C3.05396 11.5343 2.89396 11.4877 2.74729 11.4343C1.74729 11.061 1.14062 10.1943 1.14062 8.71431V5.28097C1.14062 3.22763 2.31395 2.34766 4.07395 2.34766H9.93396C11.4273 2.34766 12.5006 2.98099 12.7873 4.42765C12.834 4.69432 12.8673 4.96763 12.8673 5.28097Z"
                    stroke="white"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.8654 7.28105V10.7144C14.8654 12.7677 13.6921 13.6477 11.9321 13.6477H6.07208C5.57875 13.6477 5.13209 13.5811 4.74542 13.4344C3.95209 13.1411 3.41208 12.5344 3.21875 11.5611C3.48542 11.6211 3.77208 11.6477 4.07208 11.6477H9.93209C11.6921 11.6477 12.8654 10.7677 12.8654 8.7144V5.28105C12.8654 4.96771 12.8388 4.68773 12.7854 4.42773C14.0521 4.6944 14.8654 5.58771 14.8654 7.28105Z"
                    stroke="white"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.99927 8.76025C7.97129 8.76025 8.75928 7.97227 8.75928 7.00024C8.75928 6.02822 7.97129 5.24023 6.99927 5.24023C6.02725 5.24023 5.23926 6.02822 5.23926 7.00024C5.23926 7.97227 6.02725 8.76025 6.99927 8.76025Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.1875 5.53516V8.46851"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.8125 5.53516V8.46851"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="activity-content">
                <div>
                  <h6>{{ item.description }}</h6>
                  <h6>{{ item.created_at | moment2 }}</h6>
                </div>
                <!-- <p>₦100,000</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Service } from "../../store/service";
import moment from "moment";
import $ from "jquery";
const Api = new Service();
import PageLoader from "../PageLoader.vue";
// import paystack from "vue-paystack";
// import { staticdata } from "../../src/data";
export default {
  name: "RecentActivities",
  components: {
    PageLoader
    // paystack
  },
  props: [
    "dataForRegistration",
    "paystackReference",
    "action",
    "responseData",
    "closee"
  ],
  data() {
    return {
      loader: false,
      notifications: []
    };
  },
  // beforeMount() {},
  mounted() {
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    this.fetchNotification();
  },
  methods: {
    async fetchNotification() {
      this.loader = true;
      try {
        await Api.getRequest(`fetchuseroverdraftnotifications`)
          .then(res => {
            this.loader = false;
            if (res.data.success) {
              this.notifications = res.data.overdraft_notifications;
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.loader = false;
            console.log(err);
          });
      } finally {
        // console.log("close");
      }
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
    moment2: function(date) {
      return moment(date).format("MMMM Do YYYY");
    }
  }
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$tatiary: #a2b0e3;
$black: #000000;
$sheltaBlack: #262626;
$offBlack: #262626;
$limeGreen: #3ec250;
$sheltaGrey: #555c74;

// Recent Acitivites
.activities-holder {
  background: $secondary;
}
.recent-activity-holder {
  border-radius: 16px;
  background: #f7f9fb;
  padding: 16px;
  margin-bottom: 50px;
}
.recent-activity-text {
  color: $sheltaGrey;
  text-align: left;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  text-transform: capitalize;
  margin-bottom: 8px;
  padding: 8px 0;
}

.recent-holder {
  padding: 16px 8px;
}

.rooundedCirclePink {
  padding: 8px;
  background: #f97cda;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  margin-right: 12px;
  display: flex;
}
.rooundedCircleBlue {
  background: #849ad8;
  padding: 8px;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  margin-right: 12px;
  display: flex;
}
.activity-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  h6 {
    text-align: left;
    color: $sheltaGrey;
    text-align: left;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0;
  }
  p {
    color: #555c74;
    text-align: right;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    text-transform: capitalize;
    margin-bottom: 0;
  }
}
.view-more {
  p {
    color: $primary;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    text-transform: capitalize;
    cursor: pointer;
  }
}
// Recent Activities
</style>
